import { Analytics as DubAnalytics } from "@dub/analytics/react"
import * as React from "react"
import * as ReactDOM from "react-dom"
import { Router } from "react-router"
import { initializeSentry } from "./util/captureError"
import App from "./App"
import { Routes } from "./routes"
import { history, redirectToRoute } from "./history"
import { RecaptchaProvider } from "./RecaptchaProvider"
import { CastleProvider } from "./CastleProvider"
import { DUB_COOKIE_OPTIONS } from "./env"
import { DUB_ANALYTICS_DOMAINS_CONFIG } from "./env"

initializeSentry()

// Note: URLSearchParams uses application/x-www-form-urlencoded formate, so it
// will automatically decode the redirectUrl when its retrieved via the
// URLSearchParams.get(...) api. (source:
// https://url.spec.whatwg.org/#interface-urlsearchparams)
const params = new URLSearchParams(window.location.search)

if (params.has("token")) {
  const token = params.get("token")

  const newParams = new URLSearchParams(params)
  newParams.delete("token")

  // Ensure that we preserve the "close" parameter and any other parameters after redirect
  const queryString = newParams.toString()

  history.replace(`${Routes.completeSignUp}${token}/${queryString ? `?${queryString}` : ""}`)
}

if (params.has("invite")) {
  redirectToRoute(Routes.invite)
}

ReactDOM.render(
  <RecaptchaProvider>
    <DubAnalytics cookieOptions={DUB_COOKIE_OPTIONS} domainsConfig={DUB_ANALYTICS_DOMAINS_CONFIG} />
    <CastleProvider>
      <Router history={history}>
        <App
          backToFramerUrl={params.get("ref") === "site" ? document.referrer : null}
          error={params.get("error")}
          invite={params.get("invite")}
          isInviteForNewUser={params.get("existing") === "false"}
          redirect={params.get("redirect")}
          source={params.get("source")}
          origin={params.get("origin")}
        />
      </Router>
    </CastleProvider>
  </RecaptchaProvider>,
  document.getElementById("root") as HTMLElement
)
